import { FC, default as React } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import { InputLabelCustom, InputWrapper } from '../UI';
import { useField } from 'formik';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { ErrorLabel } from './ErrorLabel';

type Props = {
  name: string;
  value: string;
  label?: string;
  handleBlur?(e: React.FocusEvent<any>): void;
  handleChange: any;
  inFormik?: boolean;
};

export const TextInput: FC<Props & TextFieldProps> = (props) => {
  const { value, label, handleChange, handleBlur, name, ...rest } = props;

  return (
    <InputWrapper>
      <InputLabelCustom>{label}</InputLabelCustom>
      <TextField
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        type="text"
        fullWidth
        {...rest}
      />
    </InputWrapper>
  );
};

export const FormikTextInput: FC<TextFieldProps> = (props) => {
  const { name, label, ...rest } = props;
  const [field, meta] = useField(name);

  const { onChange, value, onBlur } = field;
  const { error, touched } = meta;
  const hasError = error && touched;

  return (
    <InputWrapper>
      <InputLabelCustom>{label}</InputLabelCustom>
      <TextField
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={hasError}
        variant="outlined"
        type="text"
        fullWidth
        {...rest}
      />
      {name === 'phoneNumber' && (
        <Typography fontStyle="italic" fontSize={14}>
          Zadej prosím i předvolbu +421/+420
        </Typography>
      )}
      {hasError && <ErrorLabel error={error} />}
    </InputWrapper>
  );
};
